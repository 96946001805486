import {createContext, useContext} from 'react';
import {DefaultLocale} from '../locales';

interface LocaleState {
  code: string;
}

export const LocaleContext = createContext<LocaleState>({
  code: DefaultLocale.code,
});

export function useCurrentLocale(): LocaleState {
  return useContext(LocaleContext);
}
