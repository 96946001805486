import type {FirebaseOptions} from 'firebase/app';

type Environment = 'development' | 'production' | 'test';

type LocaleSource = 'bundle' | 'esmsh' | 'jsdelivr';

export const environment: Environment =
  (process.env.NODE_ENV as Environment) ?? (import.meta.env.PROD ? 'production' : 'development');

export interface SessionExtensionConfiguration {
  /**
   * If > 0, allows the user to extend the session by this many minutes.
   * Keep this in sync with sessionExtensionPeriodMinutes in firestore.rules
   */
  extensionPeriodMinutes: number;

  /**
   * How long before a session expires should the session extension modal be displayed?
   */
  warningPeriodMinutes: number;
}

export interface SessionExpirationConfiguration {
  /**
   * Automatically logs the user out this many minutes after logging in
   * unless the session is extended (see sessionExtensionPeriodMinutes).
   * Keep this in sync with maximumSessionLengthMinutes in firestore.rules
   */
  maximumSessionLengthMinutes: number;

  /**
   * Configure to allow sessions to be extended beyond the initial expiration time.
   */
  sessionExtension?: SessionExtensionConfiguration;
}

interface FirebaseRemoteConfiguration {
  fetchTimeoutMillis: number;
  minimumFetchIntervalMillis: number;
}

interface AuthenticationConfiguration {
  google: {
    /**
     * The sign-in method to use for Google.
     * Before setting this to redirect, please read the following documentation:
     * https://firebase.google.com/docs/auth/web/redirect-best-practices
     */
    signInMethod: 'popup' | 'redirect';
  };
}

interface Configuration {
  authentication: AuthenticationConfiguration;
  firebase: FirebaseOptions;
  firebaseRemoteConfiguration: FirebaseRemoteConfiguration;
  localeSource: LocaleSource;
  sessionExpiration?: SessionExpirationConfiguration;
}

type OptionalConfigurations = Pick<Configuration, 'authentication' | 'firebaseRemoteConfiguration' | 'localeSource'>;

const ConfigurationDefaults: OptionalConfigurations = {
  authentication: {
    google: {
      signInMethod: 'popup',
    },
  },
  firebaseRemoteConfiguration: {
    fetchTimeoutMillis: 10_000,
    minimumFetchIntervalMillis: 60_000,
  },
  localeSource: 'jsdelivr',
};

const configurations: Record<Environment, Configuration> = {
  development: {
    ...ConfigurationDefaults,
    firebase: {
      apiKey: 'AIzaSyAJRfzprHRxcwtNkZzrjtrWKg3YB3yTFMk',
      appId: '1:887181067095:web:f537c6b6c4bd37655db412',
      authDomain: 'jbaldassari-firebase-quikstart.firebaseapp.com',
      messagingSenderId: '887181067095',
      projectId: 'jbaldassari-firebase-quikstart',
      storageBucket: 'jbaldassari-firebase-quikstart.appspot.com',
    },
    /*
    sessionExpiration: {
      maximumSessionLengthMinutes: 5,
      sessionExtension: {
        extensionPeriodMinutes: 5,
        warningPeriodMinutes: 4,
      },
    },
    */
  },
  production: {
    ...ConfigurationDefaults,
    authentication: {
      google: {
        signInMethod: 'redirect',
      },
    },
    firebase: {
      apiKey: 'AIzaSyAJRfzprHRxcwtNkZzrjtrWKg3YB3yTFMk',
      appId: '1:887181067095:web:f537c6b6c4bd37655db412',
      authDomain: 'app.baldassari.co',
      messagingSenderId: '887181067095',
      projectId: 'jbaldassari-firebase-quikstart',
      storageBucket: 'jbaldassari-firebase-quikstart.appspot.com',
    },
    /*
    sessionExpiration: {
      maximumSessionLengthMinutes: 60,
      sessionExtension: {
        extensionPeriodMinutes: 30,
        warningPeriodMinutes: 10,
      },
    },
    */
  },
  test: {
    ...ConfigurationDefaults,
    // This configuration is only used while executing unit tests
    firebase: {
      // Firebase config not needed for test environment, but the property needs to be defined
    },
    localeSource: 'bundle',
  },
};

export const configuration = configurations[environment];
