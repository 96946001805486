import {useCallback, useEffect, useState} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {BeforeInstallPromptEvent, InstallOutcome} from '../types';

export type UseAppInstallPrompt = {
  appInstallPrompt?: () => Promise<InstallOutcome>;
};

/* c8 ignore start */
// Can't test PWA install events
export function useAppInstallPrompt(): UseAppInstallPrompt {
  const isInApp = useMediaQuery('(display-mode: standalone)'); // Already opened as a standalone app?
  const [installPrompt, setInstallPrompt] = useState<BeforeInstallPromptEvent>();

  const handleInstallPrompt = useCallback((event: BeforeInstallPromptEvent) => {
    setInstallPrompt(event);
  }, []);

  useEffect(() => {
    globalThis.addEventListener('beforeinstallprompt', handleInstallPrompt);
    return () => {
      globalThis.removeEventListener('beforeinstallprompt', handleInstallPrompt);
    };
  }, [handleInstallPrompt]);

  const prompt = useCallback(async () => {
    if (!installPrompt) {
      throw new Error('Install prompt has not been captured');
    }
    void installPrompt.prompt();
    const {outcome} = await installPrompt.userChoice;
    setInstallPrompt(undefined); // prompt() can only be called once per captured event
    return outcome;
  }, [installPrompt, setInstallPrompt]);

  return {
    appInstallPrompt: installPrompt && !isInApp ? prompt : undefined,
  };
}
/* c8 ignore stop */
