/* c8 ignore start */
// Not possible to test this hook because document.getAnimations() is not available in vitest
// Might be possible to add coverage in the future using https://www.npmjs.com/package/jsdom-testing-mocks#mock-web-animations-api

import {RefObject, useLayoutEffect, useRef} from 'react';

let stashedTime: number | undefined;

/**
 * Ensures that all animations with the given label use a synchronized currentTime property.
 * This eliminates jitter on rerender and creates a less distracting experience when there are
 * multiple animations of the same time on the page.
 * See https://www.youtube.com/watch?v=3kDVachh-BM for inspiration.
 * @param label
 * @returns
 */
export function useSynchronizedAnimation(label: string): RefObject<unknown> {
  const ref = useRef(undefined);
  useLayoutEffect(() => {
    if (!document.getAnimations) {
      // Not available when running in unit tests
      return;
    }

    // Find all animations with the given label,
    // along with other animations which do not have the same label
    // but might be related by parent-child relationship:
    const animationsWithTargetLabel: Animation[] = [];
    const animationsWithoutTargetLabel: Animation[] = [];
    document.getAnimations().forEach((animation) => {
      if ((animation.effect as KeyframeEffect).target?.ariaLabel === label) {
        animationsWithTargetLabel.push(animation);
      } else {
        animationsWithoutTargetLabel.push(animation);
      }
    });
    if (!animationsWithTargetLabel.length) {
      return;
    }

    // We use the first animation as the reference point for the currentTime,
    // and we find the "this" animation by looking for ref returned by this hook:
    const firstAnimation = animationsWithTargetLabel[0];
    const thisAnimation = animationsWithTargetLabel.find(
      (animation) => (animation.effect as KeyframeEffect).target === ref.current,
    );
    if (!thisAnimation) {
      return;
    }

    if (thisAnimation === firstAnimation) {
      // If this is the first animation and we have previously stashed the time
      // from a previous render, use the stashed time:
      if (stashedTime) {
        setCurrentTime(thisAnimation, animationsWithoutTargetLabel, stashedTime);
      }
    } else {
      // If this is not the first animation, set our currentTime to the time of the first animation:
      setCurrentTime(thisAnimation, animationsWithoutTargetLabel, Number(firstAnimation.currentTime));
    }
    return () => {
      // When the first animation is unmounted, stash its current time to be used on the next mount:
      if (thisAnimation === firstAnimation && thisAnimation.currentTime) {
        stashedTime = Number(thisAnimation.currentTime);
      }
    };
  }, [label]);
  return ref;
}

function setCurrentTime(animation: Animation, otherAnimations: Animation[], time: number | null): void {
  animation.currentTime = time;
  // In addition to setting the currentTime of the given animation, we also attempt to set the currentTime
  // of all animations located in child nodes of the node in which the given animation is located.  The
  // reason for this is that at least some of MUI's animations (e.g. CircularProgress) are implemented
  // with multiple Animation instances, one which appears as a child of the other.
  findChildAnimations(animation, otherAnimations).forEach((childAnimation) => {
    childAnimation.currentTime = time;
  });
}

function findChildAnimations(parent: Animation, candidates: Animation[]): Animation[] {
  return candidates.filter((candidate) =>
    isParentChild((parent.effect as KeyframeEffect).target, (candidate.effect as KeyframeEffect).target),
  );
}

/**
 * Tests whether the given child candidate is a direct descendant of the given parent.
 * @param parent the parent to check.
 * @param child the node to check for a parent-child relationship.
 * @param maxDepth max distance between the parent and child nodes.
 * @returns true if the child is a direct descendant of the parent; false otherwise.
 */
function isParentChild(parent: Element | null, child: Element | null, maxDepth = 10): boolean {
  if (!parent) {
    return false;
  }
  let currentNode: Element | null = child;
  for (let depth = 0; depth < maxDepth; depth++) {
    if (!currentNode) {
      return false;
    }
    const currentNodeParent = currentNode.parentElement;
    if (currentNodeParent === parent) {
      return true;
    }
    currentNode = currentNodeParent;
  }
  return false;
}
/* c8 ignore stop */
