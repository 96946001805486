import {useState} from 'react';
import {CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {ComponentWithRequiredChildren, PropsWithRequiredChildren} from '../react/types';
import {DefaultThemeName, ThemeName, themes} from './themes';
import {FullScreenLoading} from '../common/Loading';
import {ThemeNameContext} from './hooks/useThemeName';
import {useOptionalCurrentUser} from '../users/hooks/useOptionalCurrentUser';
import {useTheme} from './hooks/useTheme';
import {useUserPreferences} from '../users/hooks/useUserPreferences';

export const CurrentThemeProvider: ComponentWithRequiredChildren = ({children}: PropsWithRequiredChildren) => {
  const maybeCurrentUser = useOptionalCurrentUser();

  if (maybeCurrentUser) {
    return <UserThemeProvider>{children}</UserThemeProvider>;
  }

  return <SimpleThemeProvider>{children}</SimpleThemeProvider>;
};

interface SimpleThemeProviderProps extends PropsWithRequiredChildren {
  theme?: ThemeName;
}
export const SimpleThemeProvider: ComponentWithRequiredChildren<SimpleThemeProviderProps> = ({
  children,
  theme = DefaultThemeName,
}: SimpleThemeProviderProps) => {
  const [themeName, setThemeName] = useState<ThemeName>(theme);
  return (
    <ThemeNameContext value={{setThemeName, themeName}}>
      <ThemeProvider theme={themes[themeName]}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeNameContext>
  );
};

const UserThemeProvider: ComponentWithRequiredChildren = ({children}: PropsWithRequiredChildren) => {
  const {theme: userTheme} = useUserPreferences();
  const [themeName, setThemeName] = useState<ThemeName>(userTheme ?? DefaultThemeName);
  const theme = useTheme(themeName);
  if (!theme) {
    return <FullScreenLoading />;
  }
  return (
    <ThemeNameContext value={{setThemeName, themeName}}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeNameContext>
  );
};
