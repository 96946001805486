import {TOptions} from 'i18next';
import React, {type JSX, ReactElement, cloneElement, useMemo} from 'react';
import {NamespacePrefixedKey, NamespaceType} from './types';
import {useTranslation} from './hooks/useTranslation';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SingleTranslationFunction = (translation: string) => ReactElement<any>;
interface SingleTranslationProps {
  children: SingleTranslationFunction;
  keys: NamespacePrefixedKey;
  options?: TOptions;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MultiTranslationFunction = (translations: string[]) => ReactElement<any>;
interface MultiTranslationProps {
  children: MultiTranslationFunction;
  keys: NamespacePrefixedKey[];
  options?: TOptions;
}

export function Translation(props: SingleTranslationProps): JSX.Element;
export function Translation(props: MultiTranslationProps): JSX.Element;
export function Translation({
  children: renderChild,
  keys,
  options,
}: SingleTranslationProps | MultiTranslationProps): JSX.Element {
  const isSingleTranslation = typeof keys === 'string';
  const translationKeys = useMemo(() => (isSingleTranslation ? [keys] : keys), [isSingleTranslation, keys]);
  const {translate} = useTranslation(extractNamespaces(translationKeys));
  const translations = useMemo(
    () => translationKeys.map((translationKey) => translate(translationKey, options)),
    [options, translate, translationKeys],
  );

  const renderedChild = isSingleTranslation
    ? (renderChild as SingleTranslationFunction)(translations[0])
    : (renderChild as MultiTranslationFunction)(translations);

  // React.Fragment can only accept 'children' and 'key' props, so we cannot add custom translation props to it
  if (renderedChild.type === React.Fragment) {
    return renderedChild;
  }

  // Automatically add the data-testid & data-translate props to the element created by the child function
  const dataTranslate = `${translationKeys[0]}`;
  const extraProps: Record<string, string> = {'data-translate': dataTranslate};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (!renderedChild.props['data-testid']) {
    extraProps['data-testid'] = dataTranslate;
  }
  return cloneElement(renderedChild, extraProps);
}

const NamespaceKeyRE = /(?<namespace>[^:]+):(?<key>[^:]+)/;
function extractNamespaces(keys: NamespacePrefixedKey[]): NamespaceType[] {
  return keys.map((key) => key.match(NamespaceKeyRE)?.groups?.namespace as NamespaceType);
}
