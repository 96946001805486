import {createContext, useCallback, useContext} from 'react';
import {ComponentWithRequiredChildren, PropsWithRequiredChildren} from '../react/types';
import {InstallOutcome} from './types';
import {useAppInstallPrompt} from './hooks/useAppInstallPrompt';
import {useStoredAppInstallOutcome} from './hooks/useStoredAppInstallOutcome';

interface AppInstallFunctions {
  dismissPrompt: () => void;
  installApp?: () => Promise<void>;
  installOutcome?: InstallOutcome;
}

/* c8 ignore start */
const AppInstallFunctionsContext = createContext<AppInstallFunctions>({
  dismissPrompt: () => {
    // Defaults to no-op; will be initialized in AppInstallFunctionsProvider
  },
});
/* c8 ignore stop */

export function useAppInstallFunctions(): AppInstallFunctions {
  return useContext(AppInstallFunctionsContext);
}

export const AppInstallFunctionsProvider: ComponentWithRequiredChildren = ({children}: PropsWithRequiredChildren) => {
  const {appInstallPrompt} = useAppInstallPrompt();
  const [installOutcome, setInstallOutcome] = useStoredAppInstallOutcome();

  const dismissPrompt = useCallback(() => setInstallOutcome('dismissed'), [setInstallOutcome]);

  const installApp = useCallback(async () => {
    setInstallOutcome(await appInstallPrompt!());
  }, [appInstallPrompt, setInstallOutcome]);

  return (
    <AppInstallFunctionsContext
      value={{
        dismissPrompt,
        installApp: appInstallPrompt ? installApp : undefined,
        installOutcome: installOutcome ?? undefined,
      }}
    >
      {children}
    </AppInstallFunctionsContext>
  );
};
