import {useState} from 'react';
import {Box} from '@mui/material';
import {ComponentWithRequiredChildren, PropsWithRequiredChildren} from '../react/types';
import {AppLoadingContext} from './hooks/useAppLoading';
import {FullScreenLoading} from '../common/Loading';
import {useDelay} from '../common/hooks/useDelay';

const DefaultMinimumLoadingTimeMillis = 250;

interface AppLoadingProps extends PropsWithRequiredChildren {
  /**
   * The amount of time to spend on the transition from app loading => app loaded, in milliseconds.
   */
  loadingCompleteTransitionTimeMillis?: number;

  /**
   * Minimum amount of time to display the loading spanner in milliseconds.
   * The reason to show the spinner for longer than necessary is to prevent a flicker effect on extremely quick loads.
   */
  minimumLoadingTimeMillis?: number;
}

export const AppLoading: ComponentWithRequiredChildren<AppLoadingProps> = ({
  children,
  loadingCompleteTransitionTimeMillis,
  minimumLoadingTimeMillis = DefaultMinimumLoadingTimeMillis,
}: AppLoadingProps) => {
  const [isAppLoading, setAppLoading] = useState<boolean>(true);
  const {hasDelayElapsed} = useDelay(minimumLoadingTimeMillis);
  const showLoadingSpinner = isAppLoading || !hasDelayElapsed;
  const maybeVisibleChildren = showLoadingSpinner ? <Box sx={{display: 'none'}}>{children}</Box> : <>{children}</>;

  return (
    <AppLoadingContext value={{isAppLoading, setAppLoading}}>
      <FullScreenLoading transitionDurationMillis={loadingCompleteTransitionTimeMillis} visible={showLoadingSpinner} />
      {maybeVisibleChildren}
    </AppLoadingContext>
  );
};
